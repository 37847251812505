import {
	AppHashHistory,
	DefaultModalName,
	IModal,
	IModalContext,
	ModalChildComponentContextKey,
	ModalContext,
} from '@AppModels/.';
import { EnvironmentKey, IEnvironmentComponentProps } from '@AppModels/AppState';
import { DeprecatedCloseButton } from '@WebComponents/DeprecatedCloseButton';
import { UnregisterCallback } from 'history';
import { observable } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import { AppAutoUpdaterRefreshBlocker } from '../AppAutoUpdaterRefreshBlocker';
import './styles.less';

let appElement: string | HTMLElement;

export interface IModalProps<TResult = any> extends IEnvironmentComponentProps {
	className?: string;
	contentPresentationStyle?: IModalPresentationStyle;
	/* Function that will be called to get the parent element that the modal will be attached to. */
	getParentSelector?(): HTMLElement;
	headerAccessory?: React.ReactElement<any>;
	isOpen: boolean;
	name?: string;
	onAfterClose?(): void;
	onRequestClose(result?: TResult, canceled?: boolean): void;
	overlayPresentationStyle?: IModalPresentationStyle;
	shouldCloseOnOverlayClick?: boolean;
	useDefaultHeader?: boolean;
}

interface IState {
	isActive?: boolean;
	isOpen?: boolean;
}

export interface IModalPresentationStyle {
	/** Will override &-after-open that is added by default */
	afterOpenClassName?: string;
	/** Will override &-before-close that is added by default */
	beforeCloseClassName?: string;
	/**
	 * Base class name. &-after-open and &-before-close will be automatically added for control over transitions unless
	 * afterOpenClassName and/or beforeCloseClassName are provided.
	 */
	className: string;
	/** Times in ms */
	transitionInDuration: number;

	/** Times in ms */
	transitionOutDuration: number;
}

export const DefaultOverlayPresentationStyle: IModalPresentationStyle = {
	className: 'modal-overlay',
	transitionInDuration: 450,
	transitionOutDuration: 450,
};

export const DefaultContentPresentationStyle: IModalPresentationStyle = {
	className: 'modal-overlay-content',
	transitionInDuration: 450,
	transitionOutDuration: 450,
};

// TODO: created a manager component that manages the display of multiple modals.
// create showAsModal and showModal functions that display in singleton modal manager component.
class _Modal<TResult> extends React.Component<IModalProps<TResult>, IState> {
	// @ts-ignore
	private mHistoryChangedUnregisterCallback: UnregisterCallback;
	public static defaultProps: Partial<IModalProps> = {
		isOpen: false,
	};
	public state: IState = {};
	/**
	 * Per React docs, we should track this as a separate var Use this with isOpen to first mount the modal and then open
	 * it (preserves presentation animations even when the modal is added to the dom in the open state).
	 */
	// @ts-ignore
	@observable private didMount: boolean;

	private mChildContextParentModalProps: IModal;
	private mChildContext: IModalContext;

	static getDerivedStateFromProps<TResult = any>(nextProps: IModalProps<TResult>, state: IState) {
		const nextState: IState = {};
		if (nextProps.isOpen !== state.isOpen) {
			nextState.isOpen = nextProps.isOpen;
			if (nextState.isOpen) {
				nextState.isActive = true;
			}
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	public constructor(props: IModalProps<TResult>) {
		super(props);
		if (!appElement && process.env.NODE_ENV !== 'test') {
			appElement = '#react-root';
			ReactModal.setAppElement(appElement);
		}

		this.mChildContextParentModalProps = {
			name: props.name || DefaultModalName,
			onRequestClose: this.onRequestClose,
		};
		this.mChildContext = { [ModalChildComponentContextKey]: this.mChildContextParentModalProps };
	}

	public componentDidMount() {
		const { isOpen } = this.props;
		this.didMount = true;
		this.setState({
			isActive: isOpen,
			isOpen,
		});

		// listen for history changes
		// do not use withRouter hoc to get history... you could get FullScreenModal history instead and that would cause onRequestClose to fire unexpectedly
		this.mHistoryChangedUnregisterCallback = AppHashHistory.listen(this.onHistoryChanged);
	}

	public componentWillUnmount() {
		this.unRegisterHistoryChangedListener();
	}

	public render() {
		const { headerAccessory, environment, children } = this.props;
		const { isActive, isOpen } = this.state;
		const useDefaultHeader = !!this.props.useDefaultHeader && !headerAccessory;
		// @ts-ignore
		const outlookClass = environment.appType === 'plugin' ? 'plugin-modal' : '';
		return (
			// @ts-ignore
			<ReactModal
				bodyOpenClassName={null}
				className={this.getModalClassNames(outlookClass)}
				closeTimeoutMS={this.closeTimeoutMS}
				isOpen={this.didMount && isOpen}
				onAfterClose={this.onAfterClose}
				onRequestClose={this.onClickOutside}
				overlayClassName={this.getOverlayClassNames(outlookClass)}
				parentSelector={this.getParentSelector}
				portalClassName={`modal ${isActive ? 'modal-active' : ''} ${this.props.className || ''}`}
				shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
			>
				<React.Fragment key='react-modal-content'>
					{!!useDefaultHeader && (
						<div className={`${this.contentPresentationStyle.className}-header`}>
							<DeprecatedCloseButton onClick={this.onClose} />
						</div>
					)}
					<Provider {...this.mChildContext}>
						<ModalContext.Provider value={this.mChildContext}>
							{headerAccessory}
							{children}
						</ModalContext.Provider>
					</Provider>
					<AppAutoUpdaterRefreshBlocker />
				</React.Fragment>
			</ReactModal>
		);
	}

	private onAfterClose = () => {
		this.setState({
			isActive: false,
			isOpen: false,
		});
		this.props.onAfterClose?.();
	};

	private getModalClassNames = (outlookClass: string) => {
		return {
			afterOpen:
				this.contentPresentationStyle.afterOpenClassName ?? `${this.contentPresentationStyle.className}-after-open`,
			base: `${this.contentPresentationStyle.className} ${outlookClass}`,
			beforeClose:
				this.contentPresentationStyle.beforeCloseClassName ?? `${this.contentPresentationStyle.className}-before-close`,
		};
	};

	private getOverlayClassNames = (outlookClass: string) => {
		return {
			afterOpen:
				this.overlayPresentationStyle.afterOpenClassName ?? `${this.overlayPresentationStyle.className}-after-open`,
			base: `${this.overlayPresentationStyle.className} ${outlookClass}`,
			beforeClose:
				this.overlayPresentationStyle.beforeCloseClassName ?? `${this.overlayPresentationStyle.className}-before-close`,
		};
	};

	private unRegisterHistoryChangedListener = () => {
		if (this.mHistoryChangedUnregisterCallback) {
			this.mHistoryChangedUnregisterCallback();
			// @ts-ignore
			this.mHistoryChangedUnregisterCallback = null;
		}
	};

	private onHistoryChanged = () => {
		if (this.state.isOpen) {
			// @ts-ignore
			this.onRequestClose(null, true);
		}
	};

	private getParentSelector = () => {
		const { getParentSelector } = this.props;
		// @ts-ignore
		const element: HTMLElement = getParentSelector ? getParentSelector() : null;
		return element || document.body;
	};

	private get overlayPresentationStyle() {
		return this.props.overlayPresentationStyle || DefaultOverlayPresentationStyle;
	}

	private get contentPresentationStyle() {
		return this.props.contentPresentationStyle || DefaultContentPresentationStyle;
	}

	private get closeTimeoutMS() {
		return Math.max(
			this.contentPresentationStyle.transitionOutDuration,
			this.overlayPresentationStyle.transitionOutDuration
		);
	}

	private onClickOutside = () => {
		// @ts-ignore
		this.onRequestClose(null, true);
	};

	private onClose = () => {
		// @ts-ignore
		this.onRequestClose(null, true);
	};

	private onRequestClose = (result?: TResult, canceled = false) => {
		if (this.props.onRequestClose) {
			// remove history listener
			this.unRegisterHistoryChangedListener();

			this.props.onRequestClose(result, canceled);
		}
	};
}

// @ts-ignore
const ModalObserver = observer(_Modal);
export const Modal = inject(EnvironmentKey)(ModalObserver);

export const setAppElement = (element: string | HTMLElement) => {
	appElement = element;
	ReactModal.setAppElement(appElement);
};

export function asModalComponent<T = any>(
	WrappedComponent: React.ComponentType<T>,
	defaultModalProps?: Partial<IModalProps>
) {
	// @ts-ignore
	const sfc: React.FunctionComponent<T & { modalProps?: IModalProps }> = (props: T & { modalProps: IModalProps }) => {
		const { modalProps, ...restProps } = props as any;
		const computedModalProps: IModalProps = {
			...(defaultModalProps || {}),
			...(modalProps || {}),
		};

		computedModalProps.className =
			!!defaultModalProps && !!defaultModalProps.className
				? `${defaultModalProps.className} ${(modalProps ? modalProps.className : undefined) || ''}`
				: computedModalProps.className;
		return (
			<Modal {...computedModalProps}>
				<WrappedComponent {...restProps} />
			</Modal>
		);
	};
	return sfc;
}

import * as React from 'react';
import { midGray } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface Props extends React.SVGProps<SVGSVGElement> {
	fillColor?: string;
}

export function XIcon({ fillColor = midGray, ...props }: Props) {
	return (
		<SvgIcon viewBox='0 0 13 13' fill='none' {...props}>
			<path
				fill={fillColor}
				fillRule='evenodd'
				d='m12.228 1.202-.974-.974-5.026 5.025L1.202.228l-.974.974 5.026 5.026-5.026 5.025.974.974 5.026-5.024 5.026 5.024.974-.974-5.026-5.025 5.026-5.026Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}

import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { ITemplate, TemplateScope } from '../../../extViewmodels';
import {
	ICreateSocialMediaPostRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	ImpersonationContextKey,
} from '../../../models';
import { CampaignType, IEditHtmlNewsletterRequest } from '../../../models/AdminModels';
import { BlogPostRequest } from '../../../models/Blogs';
import { useEnvironment, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { HtmlNewsletterViewModel } from '../../../viewmodels/HtmlNewletterViewModels';
import { bs } from '../../styles/styles';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { ISelectBoxOption } from '../SelectBox';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	showingSocialType?: boolean;
}

export const NewCampaignButtons: React.FC<IProps> = () => {
	const fullscreenModal = useFullscreenModal();
	const userSession = useUserSession();
	const onCreateCampaign = () => {
		fullscreenModal.history.push({
			pathname: '/email/campaigns/create/new-campaign',
		});
	};

	const onCreateSocialPost = () => {
		fullscreenModal.history.push({ pathname: '/social-media/post/create' });
	};

	const socialEnabled = userSession?.account?.features?.socialMedia?.enabled;

	return (
		<div className={css(styleSheet.buttonContainer)}>
			<button className={css(bs.ctaButton, styleSheet.newCampaignButton)} onClick={onCreateCampaign}>
				<span>{socialEnabled ? 'Create Email Campaign' : 'Create New Campaign'}</span>
			</button>
			{socialEnabled && (
				<button className={css(bs.ctaButton, styleSheet.newCampaignButton)} onClick={onCreateSocialPost}>
					<span>Create Social Media Post</span>
				</button>
			)}
		</div>
	);
};

interface IDropDownProps extends IImpersonationContextComponentProps {
	className?: string;
	showingSocialType?: boolean;
}

export const NewCampaignButtonsDropDown = inject(ImpersonationContextKey)(function NewCampaignButtonsDropDown({
	impersonationContext,
	showingSocialType,
}: IDropDownProps) {
	const isAdminView = useEnvironment()?.appType === 'admin';

	const fullscreenModal = useFullscreenModal();
	const userSession = useUserSession();

	const [isOpen, setIsOpen] = React.useState(false);

	const account = impersonationContext?.isValid ? impersonationContext.account : userSession.account;

	const [options] = React.useState<ISelectBoxOption<CampaignType>[]>(() => {
		const o: ISelectBoxOption<CampaignType>[] = [
			{
				title: 'Email',
				value: CampaignType.Email,
			},
		];
		if (showingSocialType && account?.features?.socialMedia?.enabled) {
			o.push({
				title: 'Social Post',
				value: CampaignType.Social,
			});
		}
		if (account?.features?.blogFeature?.enabled) {
			o.push({
				title: 'Blog Post',
				value: CampaignType.Blog,
			});
		}
		if (isAdminView && account?.features?.htmlNewsletter?.enabled) {
			o.push({
				title: 'HTML newsletter',
				value: CampaignType.htmlNewsletter,
			});
		}
		return o;
	});

	const onOpenChanged = (value: boolean) => {
		setIsOpen(value);
	};

	const navigateToPath = (path: string, state?: any) => {
		fullscreenModal?.history.push({ pathname: path, state });
	};

	const onSelectionChanged = (option: ISelectBoxOption<any>) => {
		switch (option.value) {
			case CampaignType.Social:
				onCreateSocialPost();
				break;
			case CampaignType.htmlNewsletter:
				onCreateHtmlNewsletter();
				break;
			case CampaignType.Email:
				onCreateCampaign();
				break;
			case CampaignType.Blog:
				onCreateBlogPost();
				break;
			default:
				break;
		}
		setIsOpen(false);
	};

	const onCreateHtmlNewsletter = async () => {
		if (isAdminView && impersonationContext?.account?.features?.htmlNewsletter?.enabled) {
			const template = await HtmlNewsletterViewModel.createDefaultBlankTemplate(userSession, {
				name: 'Untitled Newsletter',
				scope: TemplateScope.Account,
			});

			const locationState: ILocationState<any, IEditHtmlNewsletterRequest> = {
				model: {
					impersonationContext,
					type: 'Template',
					template,
				},
			};
			fullscreenModal?.history.push({
				pathname: `/accounts/${impersonationContext?.account?.id}/htmlnewsletter/new`,
				state: locationState,
			});
			return;
		}
	};

	const onCreateBlogPost = () => {
		if (isAdminView) {
			const locationState: ILocationState<any, BlogPostRequest> = {
				model: {
					impersonationContext,
				},
			};
			navigateToPath(`/accounts/${impersonationContext?.account?.id}/content-calendar/blog/post/create`, locationState);
			return;
		}
		navigateToPath('/blog/post/create');
	};

	const onCreateCampaign = () => {
		if (isAdminView) {
			navigateToPath(`/accounts/${impersonationContext?.account?.id}/content-calendar/campaigns/create`);
			return;
		}
		navigateToPath('/email/campaigns/create/from-scratch');
	};

	const onCreateSocialPost = () => {
		if (isAdminView) {
			const locationStateSocial: ILocationState<any, ICreateSocialMediaPostRequest<ITemplate>> = {
				model: {
					context: {},
					impersonationContext,

					sendFromUser: impersonationContext.user,
					type: 'Template',
				},
			};
			fullscreenModal?.history.push({
				pathname: `/accounts/${impersonationContext?.account?.id}/content-calendar/post/create`,
				state: locationStateSocial,
			});
			return;
		}

		navigateToPath('/social-media/post/create');
	};

	return options.length > 1 ? (
		<Dropdown
			anchor={
				<button className={css(styleSheet.buttonAnchor)}>
					<span className={css(styleSheet.buttonText)}>New</span>
					<DisclosureIcon className='select-box-trigger-caret' fillColor='white' />
				</button>
			}
			contentPositionY='bottom'
			contentClassName={css(styleSheet.menuContainer)}
			contentInnerStyle={[styleSheet.menuStyles]}
			isOpen={isOpen}
			onOpenChanged={onOpenChanged}
			openOnClick={true}
			styles={[bs.wAuto]}
		>
			<>
				{options.map((x, idx) => {
					return (
						<Button
							className={css(styleSheet.menuButton)}
							key={idx}
							kind='reverse'
							label={<span>{x.title}</span>}
							onClick={() => onSelectionChanged(x)}
							size='normal'
						/>
					);
				})}
			</>
		</Dropdown>
	) : (
		<div className={css(styleSheet.addCampaignButton)}>
			<Button
				label={<span>{`New ${options[0].title}`}</span>}
				className={css(bs.ctaButton, styleSheet.soloButton)}
				onClick={() => onSelectionChanged(options[0])}
			/>
		</div>
	);
});

import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	EmailWorkloadViewModel,
	IDayLoad,
	IEmailSendEstimate,
	IOperationResult,
	IScheduledSend,
	ScheduleCriteria,
} from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { DayModifiers, Modifier } from 'react-day-picker/types/common';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import {
	getDefaultDateStringValue,
	getScheduleTimeOptions,
	schduleSendOverlapsWithExistingWorkload,
} from '../../../../models/UiUtils';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { CalendarModifiers, CampaignDayPicker } from '../../CampaignDayPicker';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Popover, PopoverType } from '../../Popover';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { ScheduleSendIcon } from '../../svgs/icons/ScheduleSendIcon';
import { styleSheet } from './styles';

export interface IConfigureScheduledSendProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IImpersonationContextComponentProps {
	accountEmailWorkloadForSelectedMonth?: IDayLoad[];
	className?: string;
	emailWorkload?: EmailWorkloadViewModel;
	estimate?: IEmailSendEstimate;
	hideIcon?: boolean;
	initialMonth?: Date;
	initialSchedule?: IScheduledSend;
	numberOfEmailsToSend: number;
	onDone?(): Promise<any> | null | undefined;
	onMonthChanged?(date: Date): void;
	onRenderMessage?(datePassed: boolean): React.ReactNode;
	onSelectedDateChanged?(date: Date): void;
	selectedDate?: Date;
	scheduleOptions?: ISelectBoxOption<number>[];
	submitButtonText?: string;
}

interface IState {
	accountEmailWorkloadForSelectedMonth?: IDayLoad[];
	dayHover?: Date;
	estimate?: IEmailSendEstimate;
	estimatedSchduleSendOverlapsWithExistingWorkload?: boolean;
	isSending?: boolean;
	modifiers: CalendarModifiers;
	selectedDate?: Date;
	scheduleOptions?: ISelectBoxOption<number>[];
	showingCalendar?: boolean;
}

const getDerivedStateFromEstimate = (
	estimate?: IEmailSendEstimate,
	selectedDate?: Date,
	numberOfEmailsToSend?: number
) => {
	const nextState: IState = {
		estimatedSchduleSendOverlapsWithExistingWorkload: schduleSendOverlapsWithExistingWorkload(
			estimate,
			selectedDate,
			numberOfEmailsToSend
		),
		// define modifiers (dates) for days with existing work... keys map to style keys in 'modifiersStyles'
		modifiers: {
			hasSend: !!estimate && !!estimate.emailWorkload ? estimate.emailWorkload.forecast.map(x => new Date(x.date)) : [],
		},
	};

	return nextState;
};

const getDerivedStateFromAccountEmailWorkload = (accountEmailWorkload?: IDayLoad[]) => {
	const nextState: IState = {
		modifiers: {
			// @ts-ignore
			// @ts-ignore
			hasSend: accountEmailWorkload?.map(x => new Date(x.date)),
		},
	};
	return nextState;
};

class _ConfigureScheduledSend extends React.Component<IConfigureScheduledSendProps, IState> {
	private mEmailWorkloadVm: EmailWorkloadViewModel;
	// @ts-ignore
	private mEstimatePromise: Promise<any>;
	// @ts-ignore
	private mMounted: boolean;
	private todayDate: Date;

	public static getDerivedStateFromProps(props: IConfigureScheduledSendProps, state: IState) {
		let nextState: Partial<IState> = {};
		const minutes = 0;

		if (props.selectedDate !== state.selectedDate) {
			nextState = {
				...getDerivedStateFromEstimate(state.estimate, props.selectedDate, props.numberOfEmailsToSend),
				scheduleOptions: getScheduleTimeOptions(minutes, props.userSession, undefined, moment(props.selectedDate)),
				selectedDate: props.selectedDate,
			};
		}

		if (!!props.estimate && !state.estimate && props.estimate !== state.estimate) {
			nextState = {
				...getDerivedStateFromEstimate(
					props.estimate,
					state.selectedDate || props.selectedDate,
					props.numberOfEmailsToSend
				),
				estimate: props.estimate,
			};
		}

		if (props.accountEmailWorkloadForSelectedMonth !== state.accountEmailWorkloadForSelectedMonth) {
			nextState = {
				...nextState,
				...getDerivedStateFromAccountEmailWorkload(props.accountEmailWorkloadForSelectedMonth),
				accountEmailWorkloadForSelectedMonth: props.accountEmailWorkloadForSelectedMonth,
			};
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IConfigureScheduledSendProps) {
		super(props);

		const todayDate = new Date();
		const selectedDate = new Date(todayDate);
		const scheduleOptions = getScheduleTimeOptions(0, this.props.userSession, undefined, moment(selectedDate));

		selectedDate.setHours(9, 0, 0, 0);
		this.todayDate = todayDate;

		// @ts-ignore
		this.mEmailWorkloadVm = (props.emailWorkload || new EmailWorkloadViewModel(props.userSession)).impersonate(
			props.impersonationContext
		);
		this.state = {
			scheduleOptions,
			selectedDate,
			...getDerivedStateFromEstimate(props.estimate, props.selectedDate, props.numberOfEmailsToSend),
			estimate: props.estimate,
		};
	}

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public componentDidUpdate(_: IConfigureScheduledSendProps, prevState: IState) {
		const { selectedDate } = this.state;
		const {
			numberOfEmailsToSend,
			logApiError,
			logEvent,
			initialSchedule,
			estimate: initialEstimate,
			impersonationContext,
		} = this.props;
		if (
			!!selectedDate &&
			selectedDate !== prevState.selectedDate &&
			(!impersonationContext?.isValid || (!!impersonationContext?.isValid && !!impersonationContext?.user?.id))
		) {
			const schedule: IScheduledSend = {
				criteria: ScheduleCriteria.StartAfter,
				expirationDate: initialEstimate?.estimate?.expirationDate || initialSchedule?.expirationDate,
				startDate: selectedDate.toISOString(),
			};

			// @ts-ignore
			logEvent('GetEstimateScheduleSend', schedule);
			const promise = this.mEmailWorkloadVm.getScheduledSendEstimate(
				schedule,
				numberOfEmailsToSend || 1,
				moment.tz.guess()
			);
			const onFinish = (opResult: IOperationResult<IEmailSendEstimate>) => {
				if (promise === this.mEstimatePromise) {
					// @ts-ignore
					this.mEstimatePromise = null;
					const estimate = opResult.success ? opResult.value : initialEstimate || null;
					this.setState({
						// @ts-ignore
						...getDerivedStateFromEstimate(estimate, this.state.selectedDate, numberOfEmailsToSend),
						// @ts-ignore
						estimate,
					});
				}

				if (!opResult.success) {
					// @ts-ignore
					logApiError('GetEstimateScheduleSend-Error', opResult);
				}
			};
			promise.then(onFinish).catch(onFinish);
			this.mEstimatePromise = promise;
		}
	}

	public render() {
		const { className, hideIcon, onRenderMessage, submitButtonText } = this.props;
		const { estimatedSchduleSendOverlapsWithExistingWorkload, selectedDate, isSending, scheduleOptions } = this.state;

		// @ts-ignore
		const selectedTime = selectedDate ? scheduleOptions.find(x => x.value === selectedDate.getHours()) : undefined;

		return (
			<div className={`${css(styleSheet.container)} configure-scheduled-send ${className || ''}`}>
				{!hideIcon && <ScheduleSendIcon className={css(styleSheet.icon)} />}
				<div className={css(styleSheet.title)}>Schedule Send</div>
				<div className={css(styleSheet.message)}>
					{onRenderMessage
						? onRenderMessage(this.datePassed)
						: 'You can schedule the send for a later date. Please set the desired send date:'}
				</div>
				<div className={css(hideIcon ? styleSheet.dateTimeInputsInPage : styleSheet.dateTimeInputs)}>
					{hideIcon ? this.renderInPage() : this.renderPopover()}
					<DefaultSelectBox
						className={css(styleSheet.timeDropdown)}
						menuPopupPosition='top'
						onSelectionChanged={this.onTimeOptionSelected}
						options={scheduleOptions}
						optionStyles={[styleSheet.timeDropdownOption]}
						selectedOption={selectedTime}
						triggerStyles={[styleSheet.timeDropdownTrigger]}
					/>
				</div>
				{!!selectedDate && (!!estimatedSchduleSendOverlapsWithExistingWorkload || !!this.datePassed) && (
					<div className={css(styleSheet.warningMessage)}>
						{this.datePassed
							? 'The selected date has already passed. Please select a new date in the future'
							: 'Due to your daily limit and other emails you have already scheduled, we recommend starting the send sooner to prevent overlaps.'}
					</div>
				)}
				{isSending ? (
					<LoadingSpinner className={css(styleSheet.sendingLoader)} type='large' />
				) : (
					<button
						className={css(bs.ctaButton, styleSheet.setScheduleButton)}
						disabled={!selectedDate || !!isSending || !!this.datePassed}
						onClick={this.onDone}
					>
						<span>{submitButtonText || 'Set Schedule'}</span>
					</button>
				)}
			</div>
		);
	}

	@computed
	private get sendEmailAccountFeature() {
		const { userSession, impersonationContext } = this.props;
		return (
			(impersonationContext?.isValid ? impersonationContext.account.features?.sendEmail : null) ||
			userSession?.account?.features?.sendEmail
		);
	}

	@computed
	private get datePassed() {
		// @ts-ignore
		return this.props.selectedDate < this.todayDate;
	}

	private renderInPage() {
		return <div className={css(styleSheet.calendarInPageContainer)}>{this.renderDayPicker()}</div>;
	}

	private renderPopover() {
		const { showingCalendar, isSending } = this.state;
		return (
			<Popover
				anchor={this.renderPopoverAnchor()}
				dismissOnClickOutside={true}
				isOpen={!!showingCalendar && !isSending}
				onRequestClose={this.toggleCalendar(false)}
				preferredPlacement='above'
				type={PopoverType.white}
			>
				<div className={css(styleSheet.calendarPopoverContent)}>
					<div className={css(styleSheet.calendarPopoverContentHeader)}>
						<span className={css(styleSheet.calendarPopoverContentHeaderLeft)}>
							<span>Dates with</span>
							<span className={css(bs.bgBlack, bs.roundedFull, bs.inlineBlock, bs.h2, bs.w2, bs.overflowVisible)} />
							<span>have scheduled sends</span>
						</span>
						<DeprecatedCloseButton onClick={this.toggleCalendar(false)} />
					</div>
					{this.renderDayPicker()}
				</div>
			</Popover>
		);
	}

	private renderDayPicker() {
		const { modifiers, selectedDate } = this.state;
		const { initialMonth, onMonthChanged, estimate, initialSchedule } = this.props;
		const expirationDate = estimate?.estimate ? estimate?.estimate?.expirationDate : initialSchedule?.expirationDate;
		const disabledDays = expirationDate
			? [{ after: new Date(expirationDate), before: new Date() }]
			: [{ before: new Date() }];
		const businessHours = this.sendEmailAccountFeature;
		const outsideBusinessHourDays: Modifier[] = [];
		if (businessHours?.observeSendIntervals) {
			if (!businessHours?.saturdayInterval?.startMinutes && !businessHours?.saturdayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [6] });
			}
			if (!businessHours?.sundayInterval?.startMinutes && !businessHours?.sundayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [0] });
			}
			if (!businessHours?.fridayInterval?.startMinutes && !businessHours?.fridayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [5] });
			}
		}
		return (
			<CampaignDayPicker
				defaultSelectedDate={selectedDate}
				disabledDays={[...outsideBusinessHourDays, ...disabledDays]}
				initialMonth={initialMonth}
				modifiers={modifiers}
				onDayClick={this.onDateSelected}
				onMonthChange={onMonthChanged}
				selectedDay={selectedDate}
				numWeeksShown={6}
			/>
		);
	}

	private renderPopoverAnchor() {
		const { selectedDate } = this.state;

		return (
			<button
				className={css(bs.textField, styleSheet.inputButton)}
				onClick={this.toggleCalendar(!this.state.showingCalendar)}
			>
				<span className={css(styleSheet.inputButtonText)}>
					<span className={css(!selectedDate ? styleSheet.inputButtonPlaceholder : null)}>
						{selectedDate ? getDefaultDateStringValue(selectedDate) : 'mm/dd/yyyy'}
					</span>
				</span>
			</button>
		);
	}

	private onDone = () => {
		const { onDone } = this.props;
		if (onDone) {
			const promise = onDone();
			if (promise) {
				this.setState({
					isSending: true,
				});
				const onFinish = () => {
					if (this.mMounted) {
						this.setState({
							isSending: false,
						});
					}
				};
				promise.then(onFinish).catch(onFinish);
			}
		}
	};

	private onTimeOptionSelected = (option: ISelectBoxOption<number>) => {
		const { onSelectedDateChanged } = this.props;
		const { selectedDate } = this.state;
		const nextSelectedDate = selectedDate ? new Date(selectedDate) : new Date();
		// @ts-ignore
		nextSelectedDate.setHours(option.value, 0);
		const nextScheduleOptions = getScheduleTimeOptions(0, this.props.userSession, undefined, moment(nextSelectedDate));
		this.setState(
			{
				scheduleOptions: nextScheduleOptions,
				selectedDate: nextSelectedDate,
			},
			() => {
				if (onSelectedDateChanged) {
					onSelectedDateChanged(nextSelectedDate);
				}
			}
		);
	};

	private toggleCalendar = (showingCalendar: boolean) => () => {
		this.setState({
			showingCalendar,
		});
	};

	private onDateSelected = (selectedStartDate: Date, _: DayModifiers, e: React.MouseEvent<HTMLElement>) => {
		if (moment(selectedStartDate).isBefore(moment().startOf('day'))) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}
		const { onSelectedDateChanged } = this.props;
		const { selectedDate } = this.state;
		const nextSelectedDate = new Date(selectedStartDate);

		if (selectedDate) {
			nextSelectedDate.setHours(selectedDate.getHours(), 0);
		} else {
			nextSelectedDate.setHours(9, 0);
		}
		const nextScheduleOptions = getScheduleTimeOptions(0, this.props.userSession, undefined, moment(nextSelectedDate));

		this.setState(
			{
				scheduleOptions: nextScheduleOptions,
				selectedDate: nextSelectedDate,
			},
			() => {
				if (onSelectedDateChanged) {
					onSelectedDateChanged(nextSelectedDate);
				}
			}
		);
	};
}

// @ts-ignore
const ConfigureScheduledSendAsObserver = observer(_ConfigureScheduledSend);
const ConfigureScheduledSendWithContext = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(ConfigureScheduledSendAsObserver);
export const ConfigureScheduledSend = withEventLogging(ConfigureScheduledSendWithContext, 'ConfigureScheduledSend');

import * as Api from '@ViewModels';
import { StyleDeclaration, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { integrationSources } from '../../../../extViewmodels/Utils';
import { isIE11 } from '../../../../models/Browser';
import { useEventLogging } from '../../../../models/Logging';
import {
	contactIsFromEnabledIntegration,
	getDefaultDateStringValue,
	getEntityDisplayName,
} from '../../../../models/UiUtils';
import { useEnvironment, useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { Modal } from '../../../components/Modal';
import { PopoverType, TinyPopover } from '../../../components/TinyPopover';
import { InlineValueEditor } from '../../../components/entities/InlineValueEditor';
import { KeyFactHousehold } from '../../../components/entities/KeyFactHousehold';
import { KeyFactWithKeyDate } from '../../../components/entities/KeyFactWithKeyDate';
import { UneditableKeyFactIcon } from '../../../components/entities/UneditableKeyFactIcon';
import { BulletIcon } from '../../../components/svgs/icons/BulletIcon';
import { DeprecatedXIcon } from '../../../components/svgs/icons/DeprecatedXIcon';
import { EditItemPenIcon } from '../../../components/svgs/icons/EditItemPenIcon';
import { RoundAddIcon } from '../../../components/svgs/icons/RoundAddIcon';
import { TrashIcon } from '../../../components/svgs/icons/TrashIcon';
import { bs } from '../../../styles/styles';
import { DataOriginContainer } from '../../DataOriginContainer';
import { ContactInfoSmartKeyFactsModalContent } from './ContactInfoSmartKeyFactsModal';
import { styleSheet } from './styles';

interface Props {
	compactLayout: boolean;
	contactVm: Api.ContactViewModel;
	keyFactToHighlight?: Api.IKeyFact;
}

function _ContactInfoKeyFacts({ compactLayout, contactVm, keyFactToHighlight }: Props) {
	const [keyFacts, setKeyFacts] = React.useState<Api.IKeyFact[] | null>(null);
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const environment = useEnvironment();
	const { logEvent, logApiError } = useEventLogging('ContactInfoKeyFacts');
	const [itemToBeDeletedIndex, setItemToBeDeletedIndex] = React.useState<number | null>(null);
	const [editItemIndex, setEditItemIndex] = React.useState<number>(-1);
	const [editableKeyFact, setEditableKeyFact] = React.useState<Api.IKeyFact | null>(null);
	const [showEditNewItemInput, setShowEditNewItemInput] = React.useState(false);
	const [popoverVisible, setPopoverVisible] = React.useState(false);
	const [userHidKeyFactHighlightPopover, setUserHidKeyFactHighlightPopover] = React.useState(false);
	const [smartKeyFactModalOpen, setSmartKeyFactModalOpen] = React.useState(false);

	React.useEffect(() => {
		if (!keyFactToHighlight?.id) {
			return;
		}
		if (isIE11()) {
			setPopoverVisible(true);
		} else {
			const keyFactToHighlightObserver = new IntersectionObserver(
				entries => {
					setPopoverVisible(entries[0].isIntersecting);
				},
				{
					root: document.getElementById('contact-context-tabview-content'),
					rootMargin: '-40px 0px 0px',
					threshold: 0,
				}
			);
			keyFactToHighlightObserver.observe(document.getElementById(keyFactToHighlight.id));
			return () => {
				if (keyFactToHighlightObserver) {
					keyFactToHighlightObserver.disconnect();
				}
			};
		}
	}, [keyFactToHighlight?.id]);
	const onAddKeyFact = (keyFact: Api.IKeyFact) => {
		const newKeyFacts = [...contactVm.keyFactsCollection];
		newKeyFacts.push(keyFact);
		const promise = contactVm.updateKeyFactsCollection(newKeyFacts);
		if (promise) {
			logEvent('AddKeyFact');
			setKeyFacts(newKeyFacts);
			promise
				.then(() => {
					setKeyFacts(null);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					setKeyFacts(null);
					logEvent('AddKeyFact-Error', { ...error });
					errorMessages.pushApiError(error);
				});
		}
	};
	const onUpdateKeyFact = (keyFact: Api.IKeyFact) => {
		const newKeyFacts = [...contactVm.keyFactsCollection];
		// if the key fact doesn't have a value, this actually just removes it from the collection
		const index = contactVm.keyFactsCollection.findIndex(x => x.id === keyFact.id);
		if (keyFact.value) {
			newKeyFacts.splice(index, 1, keyFact);
		} else {
			newKeyFacts.splice(index, 1);
		}
		const promise = contactVm.updateKeyFactsCollection(newKeyFacts);
		if (promise) {
			logEvent('UpdateKeyFact', { id: keyFact.id });
			setKeyFacts(newKeyFacts);
			promise
				.then(() => {
					setKeyFacts(null);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					setKeyFacts(null);
					logEvent('UpdateKeyFact-Error', { ...error });
					errorMessages.pushApiError(error);
				});
		}
	};
	const onRemoveKeyDate = (keyFact: Api.IKeyFact) => {
		const promise = contactVm.removeKeyDate(keyFact);
		if (promise) {
			logEvent('RemoveKeyDate', { id: keyFact.id });
			promise.catch((error: Api.IOperationResultNoValue) => {
				logApiError('RemoveKeyDate-Error', error);
			});
		}
	};
	const disableHover = (item: Api.IKeyFact) => {
		return (
			contactIsFromEnabledIntegration(contactVm, userSession.account.integrations) &&
			item &&
			item.source?.source &&
			integrationSources.includes(item.source.source)
		);
	};
	const onEditCanceled = () => {
		setEditableKeyFact(null);
		setEditItemIndex(-1);
		setShowEditNewItemInput(false);
	};
	const onEditTextChanged = (value: string) => {
		if (editableKeyFact) {
			setEditableKeyFact({
				...editableKeyFact,
				value,
			});
		}
	};
	const onItemEditButtonClicked = (item: Api.IKeyFact, index: number) => {
		setEditableKeyFact({
			id: null,
			value: '',
			...(item || {}),
		});
		setEditItemIndex(index);
		setShowEditNewItemInput(false);
	};
	const onRemoveItem = (item: Api.IKeyFact) => {
		if (item.id) {
			onUpdateKeyFact({
				...item,
				value: null,
			});
		}
		setEditableKeyFact(null);
		setEditItemIndex(-1);
		setShowEditNewItemInput(false);
	};
	const onSaveEdit = () => {
		if (editableKeyFact) {
			setEditableKeyFact({
				...editableKeyFact,
				value: editableKeyFact.value?.trim() || '',
			});
			if (editableKeyFact.id) {
				onUpdateKeyFact(editableKeyFact);
			} else {
				onAddKeyFact(editableKeyFact);
			}
		}
		setEditableKeyFact(null);
		setEditItemIndex(-1);
		setShowEditNewItemInput(false);
	};
	const onDeleteConfirmationRequestClose = () => {
		setItemToBeDeletedIndex(null);
	};
	const onSmartKeyFactAdded = () => {
		setSmartKeyFactModalOpen(false);
		reloadContact();
	};
	const reloadContact = () => {
		return contactVm.load()?.catch((error: Api.IOperationResultNoValue) => {
			logApiError('ReloadContact-Error', error);
		});
	};
	const renderItem = (item: Api.IKeyFact, index: number) => {
		const editButton = (
			<>
				<button
					className={`entity-info-bullet-list-item-edit-button ${css(bs.pt1)}`}
					disabled={!!contactVm.isBusy}
					onClick={() => onItemEditButtonClicked(item, index)}
				>
					<EditItemPenIcon />
				</button>
				<TinyPopover
					align='end'
					anchor={
						<button
							className={`entity-info-bullet-list-item-edit-button ${css(bs.pt1, bs.px2)}`}
							disabled={!!contactVm.isBusy}
							onClick={() => setItemToBeDeletedIndex(index)}
						>
							<TrashIcon />
						</button>
					}
					arrow={{
						arrowSize: 10,
					}}
					arrowStyles={[styleSheet.deleteItemConfirmationArrow]}
					dismissOnOutsideAction={true}
					isOpen={itemToBeDeletedIndex === index}
					onRequestClose={onDeleteConfirmationRequestClose}
					placement={['top']}
					type={PopoverType.blue}
				>
					<div
						className={css(
							bs.boxBorder,
							bs.pt4,
							bs.px5,
							bs.pb5,
							bs.relative,
							styleSheet.deleteItemConfirmationContainer
						)}
					>
						<div
							className={css(styleSheet.closeDeleteConfirmationContainer)}
							onClick={onDeleteConfirmationRequestClose}
						>
							<DeprecatedXIcon />
						</div>
						<p>Do you want to delete this key fact?</p>
						<button className={css(bs.ctaButtonDestructiveSolid)} onClick={onRemoveItemButtonClicked}>
							Delete
						</button>
					</div>
				</TinyPopover>
			</>
		);
		const dateString = item.lastModifiedDate || item.creationDate;
		const displayDate = dateString ? getDefaultDateStringValue(dateString) : null;

		const isProtectedKeyFact = !!(item.source?.additionalMetadata as Api.IPolicyKeyFactMetaData)?.policyId;
		const keyFact = item.keyDate ? (
			<KeyFactWithKeyDate
				key={item.id}
				keyFact={item}
				onRemoveKeyDate={() => onRemoveKeyDate?.(item)}
				readonly={isProtectedKeyFact}
			/>
		) : (
			<span>{item.value}</span>
		);

		let styles: StyleDeclaration[] = [];
		if (environment.appType === 'plugin') {
			styles = [styleSheet.pluginEnv];
		}

		let content = (
			<>
				<div>
					{item.id === keyFactToHighlight?.id && !!keyFactToHighlight?.id ? (
						<TinyPopover
							align='start'
							anchor={keyFact}
							dismissOnOutsideAction={true}
							isOpen={!userHidKeyFactHighlightPopover && popoverVisible}
							placement={['top']}
							styles={styles}
							type={PopoverType.blue}
						>
							<div
								className={css(bs.flex, bs.justifyEnd, bs.pt2, bs.px2)}
								onClick={() => {
									setUserHidKeyFactHighlightPopover(true);
									setPopoverVisible(false);
								}}
							>
								<DeprecatedXIcon />
							</div>
							<div className={css(bs.pt2, bs.px5, bs.pb5)}>{`${getEntityDisplayName(contactVm)} has a ${
								keyFactToHighlight.keyDate.kind
							} coming up!`}</div>
						</TinyPopover>
					) : (
						keyFact
					)}
				</div>
				{!!displayDate && <div className={css(bs.textNavigation, bs.flexShrink0, bs.textXs)}>{displayDate}</div>}
			</>
		);

		content = (
			<DataOriginContainer
				className={css(styleSheet.dataOriginContainer)}
				contact={contactVm}
				keyFact={item}
				dataType='KeyFact'
				iconPlacement='center'
			>
				<div className={`${css(styleSheet.factText, bs.itemsBaseline)} key-facts-editor-item-fact-text`}>{content}</div>
			</DataOriginContainer>
		);

		const dataOriginEnabled = contactVm.dataOriginEnabled;
		const cameFromIntegration = integrationSources.includes(item.source?.source);
		const cIsFromEnabledIntegration = contactIsFromEnabledIntegration(contactVm, userSession.account.integrations);
		let edit: React.ReactNode = null;

		if ((cameFromIntegration && !dataOriginEnabled && cIsFromEnabledIntegration) || isProtectedKeyFact) {
			edit = <UneditableKeyFactIcon keyFact={item} />;
		} else if (
			(!dataOriginEnabled && !item.source?.source) ||
			item.source?.source === Api.IntegrationSources.CsvImport
		) {
			// If there is no source (you created it yourself) then you should still be able to edit it
			edit = editButton;
		}

		return (
			<>
				{content}
				{edit}
			</>
		);
	};
	const onRemoveItemButtonClicked = () => {
		const itbdIndex = itemToBeDeletedIndex;
		setItemToBeDeletedIndex(null);
		const index = editItemIndex >= 0 ? editItemIndex : itbdIndex != null ? itbdIndex : null;
		if (index != null) {
			onRemoveItem(items[index]);
		}
		setItemToBeDeletedIndex(null);
	};
	const items = keyFacts || contactVm.keyFactsCollection || [];
	const editingExistingItem = editItemIndex >= 0;
	const spouseContact =
		contactVm.householdMembers?.length &&
		contactVm.householdMembers.find(member => {
			return member.id !== contactVm.id && member.householdRelationship?.title?.toLowerCase() === 'spouse';
		});
	return (
		<div>
			<ul className={css(bs.listDecimal)}>
				{items.map((item, i) => {
					const isEditing = i === editItemIndex;
					const className = `${css(
						styleSheet.item,
						!editingExistingItem && !contactVm.dataOriginEnabled && !disableHover(item)
							? styleSheet.itemWithHover
							: null,
						itemToBeDeletedIndex === i ? styleSheet.itemToBeDeleted : null,
						isEditing ? styleSheet.itemEditing : null
					)}`;
					return (
						<li className={className} key={`${item}-${i}`}>
							{isEditing ? (
								<>
									<div
										className={`${css(
											bs.flex,
											bs.itemsCenter,
											bs.flexShrink0,
											bs.h4,
											bs.justifyCenter,
											bs.mr2,
											bs.w4
										)} entity-info-bullet-list-item-bullet`}
									>
										<button onClick={onRemoveItemButtonClicked}>
											<DeprecatedXIcon />
										</button>
									</div>
									<InlineValueEditor
										autoComplete='off'
										autoFocus={true}
										compactLayout={compactLayout}
										disabled={!!contactVm.isBusy}
										id='entity-info-bullet-list-editor-input'
										onCancelButtonClicked={onEditCanceled}
										onChange={ev => onEditTextChanged(ev.target.value)}
										onKeyDown={ev => {
											if (ev.keyCode === 13) {
												onSaveEdit();
											}
										}}
										onSaveButtonClicked={onSaveEdit}
										value={editableKeyFact?.value || ''}
									/>
								</>
							) : (
								<>
									<div
										className={`${css(
											bs.flex,
											bs.itemsCenter,
											bs.flexShrink0,
											bs.h4,
											bs.w4,
											bs.justifyCenter,
											bs.mr2
										)} entity-info-bullet-list-item-bullet`}
									>
										<BulletIcon />
									</div>
									{renderItem(item, i)}
								</>
							)}
						</li>
					);
				})}
				{spouseContact ? <KeyFactHousehold spouseContact={spouseContact} onHouseholdDeleted={reloadContact} /> : null}
			</ul>
			<div className={css(bs.flex, bs.itemsCenter, bs.h8, bs.relative)}>
				<RoundAddIcon className={`${css(bs.mr2)} entity-info-bullet-list-footer-icon`} />
				{showEditNewItemInput ? (
					<InlineValueEditor
						autoComplete='off'
						autoFocus={true}
						compactLayout={compactLayout}
						disabled={!!contactVm.isBusy}
						id='entity-info-bullet-list-editor-input'
						onCancelButtonClicked={onEditCanceled}
						onChange={ev => onEditTextChanged(ev.target.value)}
						onKeyDown={ev => {
							if (ev.keyCode === 13) {
								onSaveEdit();
							}
						}}
						onSaveButtonClicked={onSaveEdit}
						value={editableKeyFact?.value || ''}
					/>
				) : (
					<button
						className={`${css(bs.px1)} brand-link`}
						disabled={!!contactVm.isBusy || editItemIndex >= 0}
						onClick={() => setSmartKeyFactModalOpen(true)}
					>
						<span>Add a key fact</span>
					</button>
				)}
			</div>
			<Modal
				isOpen={smartKeyFactModalOpen}
				onRequestClose={() => setSmartKeyFactModalOpen(false)}
				useDefaultHeader
				shouldCloseOnOverlayClick
			>
				{smartKeyFactModalOpen ? (
					<ContactInfoSmartKeyFactsModalContent
						compactLayout={compactLayout}
						contact={contactVm.toJs()}
						onClose={() => setSmartKeyFactModalOpen(false)}
						onSuccess={onSmartKeyFactAdded}
					/>
				) : null}
			</Modal>
		</div>
	);
}

export const ContactInfoKeyFacts = observer(_ContactInfoKeyFacts);

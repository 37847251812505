import { AutomationTemplateViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { PopoverPlacement } from 'react-popover';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Popover, PopoverType } from '../../Popover';
import { DeprecatedXIcon } from '../../svgs/icons/DeprecatedXIcon';
import { styleSheet } from './styles';

interface IProps {
	anchor?: React.ReactNode;
	automationTemplate?: AutomationTemplateViewModel;
	className?: string;
	isOpen?: boolean;
	onCloseClicked?(): void;
	onCtaClicked?(): void;
	preferredPlacement?: PopoverPlacement;
	styles?: StyleDeclarationValue[];
}

class _AutomationPopoverPrompt extends React.Component<IProps> {
	public render() {
		const { automationTemplate, anchor, isOpen, preferredPlacement, onCtaClicked, onCloseClicked } = this.props;
		return !automationTemplate ? null : (
			<Popover
				anchor={anchor}
				isOpen={!!isOpen}
				type={PopoverType.blue}
				className='automation-popover'
				preferredPlacement={preferredPlacement}
			>
				<div className={css(styleSheet.automationPopover)}>
					<div className={css(styleSheet.automationXIcon)} onClick={onCloseClicked}>
						<DeprecatedXIcon />
					</div>
					<div className={`automation-popover-message ${css(styleSheet.automationPopoverMessage)}`}>
						This tag can trigger an automation flow:
					</div>
					{automationTemplate?.isLoaded ? (
						<>
							<div className={css(styleSheet.automationPopoverName)}>{`${automationTemplate?.name || ''}`}</div>
							<button
								className={css(baseStyleSheet.ctaButtonSmall, styleSheet.automationStartButton)}
								onClick={onCtaClicked}
							>
								<span>Start Automation</span>
							</button>
						</>
					) : (
						<LoadingSpinner type='tiny' />
					)}
				</div>
			</Popover>
		);
	}
}

export const AutomationPopoverPrompt = observer(_AutomationPopoverPrompt);

import { StyleSheet } from 'aphrodite';
import { background, brandPrimary, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionButton: {
		':hover': {
			textDecoration: 'underline',
		},
		color: brandPrimary,
		fontSize: 14,
		paddingTop: 12,
		width: '100%',
	},
	actionButtonLast: {
		paddingBottom: 12,
	},
	actionIcon: {
		height: 14,
		marginRight: 6,
	},
	addressAddButtonWrapper: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	addressDisplay: {
		paddingTop: '0.5rem',
		whiteSpace: 'pre-line',
	},
	addressInfoField: {
		':hover': {
			...CssSelectors.allDescendants(
				{
					opacity: 1,
					pointerEvents: 'auto',
				},
				'.contact-info-edit-address-btn'
			),
			background,
		},
		alignItems: 'flex-end',
		display: 'flex',
		justifyContent: 'space-between',
		position: 'relative',
	},
	addressInfoFieldEditBtn: {
		opacity: 0,
		pointerEvents: 'none',
		position: 'absolute',
		right: 0,
		top: 0,
	},
	bio: {
		whiteSpace: 'pre-wrap',
	},
	container: {
		boxSizing: 'border-box',
		position: 'relative',
	},
	fieldEntity: {
		marginTop: 20,
	},
	fieldEntitySection: {
		':first-child': {
			marginTop: -20,
		},
		':last-child': {
			borderBottom: '1px solid #D8D8D8',
			marginBottom: 20,
			paddingBottom: 20,
		},
		borderTop: '1px solid #D8D8D8',
		marginTop: 20,
		paddingTop: 20,
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	keyFactsAndTags: {
		marginTop: 20,
	},
	kitDropdown: {
		display: 'block',
		marginTop: 25,
	},
	loading: {
		marginTop: 30,
	},
	phoneNumber: {
		color: titles,
		fontSize: 14,
	},
	phoneNumberGroup: {
		':not(:first-child)': {
			marginTop: 12,
		},
		fontSize: 14,
	},
	relationshipHealth: {
		marginTop: 25,
	},
	unsubscribeCheckbox: {
		color: titles,
		marginBottom: 20,
	},
	customPropertyDisplay: {
		fontSize: 14,
		marginBottom: 15,
	},
});

import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { darkGrayFontColor } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { TinyPopover } from '../TinyPopover';
import { CheckmarkIcon } from '../svgs/icons/CheckmarkIcon';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { selectBoxHeight, styleSheet } from './styles';

const DEFAULT_MAX_OPTIONS_TO_SHOW = 5;

export interface ISelectOption<T = any> {
	checked?: boolean;
	children?: any[];
	component?: (style: StyleDeclarationValue, selected: boolean, asTrigger?: boolean) => React.ReactNode;
	dataContext: T;
	forceSelectAll?: boolean;
	hoverText?: string;
	icon?: React.ReactNode;
	id: string;
	label?: string;
	name?: string;
	preventClose?: boolean;
	styles?: StyleDeclarationValue[];
	text?: string;
	type?: 'blue' | 'checkbox' | 'icon' | 'default';
}

interface ISelectBaseProps<T = any> {
	disabled?: boolean;
	dropdownStyles?: StyleDeclarationValue[];
	maxOptionsToShow?: number;
	onOpenChanged?(isOpen: boolean): void;
	onOptionClick?(option: ISelectOption<T>, wasSelected: boolean): void;
	onRenderPlaceholder?(): React.ReactNode;
	onTriggerClick?(isOpen: boolean): void;
	openOnHover?: boolean;
	optionClassName?: StyleDeclarationValue[];
	options: ISelectOption<T>[];
	optionsStyle?: React.CSSProperties;
	optionStyles?: StyleDeclarationValue[];
	renderOption?(
		option: ISelectOption<T>,
		isSelected: boolean,
		baseStyles: StyleDeclarationValue[],
		asTrigger?: boolean
	): JSX.Element | React.ReactNode;
	showCaret?: boolean;
	styles?: StyleDeclarationValue[];
	triggerCaretFillColor?: string;
	triggerStyles?: StyleDeclarationValue[];
}

export interface ISelectProps<T = any> extends ISelectBaseProps<T> {
	selectedOption?: ISelectOption<T>;
	selectedOptionTitle?: React.ReactNode | ((selectedOptions: ISelectOption<T>) => JSX.Element | null);
}

export interface IMultiSelectProps<T = any> extends ISelectBaseProps<T> {
	isAllSelected?: boolean;
	onAllClicked?(): void;
	onClose?(selectedOptions: ISelectOption<T>[]): void;
	onOptionClick?(option: ISelectOption<T>, wasSelected: boolean): void | ISelectOption<T>[];
	selectAllIfNoneSelected?: boolean;
	selectAllOption?: ISelectOption<T>;
	selectedOptions?: ISelectOption<T>[];
	selectedOptionsTitle?: React.ReactNode | ((selectedOptions: ISelectOption<T>[]) => JSX.Element | null);
}

interface ISelectBaseState<T> {
	isHovering?: boolean;
	isOpen?: boolean;
	anchorContentRect?: ContentRect;
	options?: ISelectOption<T>[];
}

type ISelectState<T> = ISelectBaseState<T>;

interface IMultiSelectState<T> extends ISelectBaseState<T> {
	forceSelectAllFound?: boolean;
	selectedOptions?: ISelectOption<T>[];
}

abstract class SelectBase<T, P extends ISelectBaseProps<T>, S extends ISelectBaseState<T>> extends React.Component<
	P,
	S
> {
	protected mMounted = false;
	protected onTriggerMouseLeaveTimeout: any;

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
		this.clearTimeout();
	}

	public componentDidUpdate(_: P, prevState: S) {
		const { isOpen } = this.state;
		if (isOpen !== prevState.isOpen) {
			this.props.onOpenChanged?.(isOpen);
		}
	}

	public render() {
		const {
			styles,
			maxOptionsToShow = DEFAULT_MAX_OPTIONS_TO_SHOW,
			dropdownStyles,
			disabled,
			optionsStyle = {},
		} = this.props;
		const { isOpen, anchorContentRect } = this.state;

		const optionsContainerStyle: React.CSSProperties = {
			maxHeight: `${selectBoxHeight * maxOptionsToShow + selectBoxHeight / 2}px`,
			minWidth: anchorContentRect?.client ? `${anchorContentRect.client.width}px` : undefined,
			...optionsStyle,
		};

		return (
			<div className={css(styleSheet.container, isOpen && styleSheet.containerOpen, ...(styles || []))}>
				<TinyPopover
					anchor={this.renderTrigger()}
					disabled={disabled}
					dismissOnOutsideAction={true}
					isOpen={isOpen}
					onRequestClose={this.onClose}
					placement={['bottom', 'top']}
					styles={[styleSheet.popover, ...(dropdownStyles || [])]}
				>
					<div className={`${css(styleSheet.optionsContainer)} options-container`} style={optionsContainerStyle}>
						{this.renderOptions()}
					</div>
				</TinyPopover>
			</div>
		);
	}

	protected abstract onClose: () => void;
	protected abstract renderOptions: () => JSX.Element[];
	protected abstract renderTrigger: () => JSX.Element;

	protected onAnchorResized = (contentRect: ContentRect) => {
		if (!equal(this.state.anchorContentRect, contentRect)) {
			this.setState({
				anchorContentRect: contentRect,
			});
		}
	};

	protected clearTimeout = () => {
		window.clearTimeout(this.onTriggerMouseLeaveTimeout);
	};

	protected onHover = (hoverOn: boolean) => () => {
		this.clearTimeout();
		const nextState: ISelectBaseState<T> = {
			...this.state,
			isHovering: hoverOn,
		};
		if (this.state.isOpen && !hoverOn) {
			nextState.isOpen = false;
		}
		this.setState(nextState);
	};

	protected onTargetHover = (hoverOn: boolean, cb?: () => void) => () => {
		if (!this.state.isOpen && hoverOn) {
			this.setState({ isOpen: true }, cb);
		} else {
			// delay the closing of the menu in case the user is moving from the trigger to the menu with the mouse.
			this.onTriggerMouseLeaveTimeout = setTimeout(() => {
				if (this.state.isOpen && !this.state.isHovering && !hoverOn) {
					this.setState({
						isOpen: false,
					});
					this.clearTimeout();
				}
			}, 75);
		}
	};

	protected renderOption = (option: ISelectOption<T>, selected: boolean, asTrigger?: boolean) => {
		const { renderOption, triggerStyles } = this.props;

		const computedStyles: StyleDeclarationValue[] = asTrigger
			? [styleSheet.trigger, ...(triggerStyles || [])]
			: [styleSheet.option, ...(option.styles || [])];
		computedStyles.push(selected && !asTrigger ? styleSheet.selected : null);

		if (renderOption) {
			return renderOption(option, selected, computedStyles, asTrigger);
		}
		if (!option) {
			return (
				<div
					className={`${css(computedStyles)} ${selected && !asTrigger && 'selected'} ${
						asTrigger ? 'select-trigger' : 'select-option'
					} select-option-hover-prevented`}
				/>
			);
		}

		switch (option.type) {
			case 'blue':
				computedStyles.push(selected && !asTrigger ? styleSheet.selectedBlue : null);
				return (
					<div
						className={`${css(computedStyles)} ${selected && !asTrigger && 'selected-blue'} ${
							asTrigger ? 'select-trigger' : 'select-option'
						}${option.preventClose ? ' select-option-hover-prevented' : ''}`}
						title={option.hoverText || option.text}
					>
						<div className={`${css(styleSheet.optionInner)} select-option-inner`}>
							<div className={`${css(styleSheet.selectText)} select-option-text`}>{option.text}</div>
						</div>
					</div>
				);
			case 'checkbox':
				return (
					<div
						className={`${css(computedStyles)} ${asTrigger ? 'select-option' : 'select-option'}${
							option.preventClose ? ' select-option-hover-prevented' : ''
						}`}
						title={option.hoverText || option.text}
					>
						<div className={`${css(styleSheet.optionInner)} select-option-inner`}>
							<div className={`${css(styleSheet.checkmark, bs.flexShrink0)} select-checkmark`}>
								<CheckmarkIcon className={css(baseStyleSheet.absoluteCenter)} fillColor={!selected && 'transparent'} />
							</div>
							<div className={`${css(styleSheet.selectText)} select-option-text`}>{option.text}</div>
						</div>
					</div>
				);
			case 'icon':
				return (
					<div
						className={`${css(computedStyles)} ${selected && !asTrigger && 'selected'} ${
							asTrigger ? 'select-trigger' : 'select-option'
						}${option.preventClose ? ' select-option-hover-prevented' : ''}`}
						title={option.hoverText || option.text}
					>
						<div className={`${css(styleSheet.optionInner)} select-option-inner`}>
							<div className={`${css(styleSheet.icon)} select-icon`}>
								<div className={css(styleSheet.iconWrapper)}>{option.icon}</div>
							</div>
							<div className={`${css(styleSheet.selectText)} select-option-text`}>{option.text}</div>
						</div>
					</div>
				);
			default:
				return (
					<div
						className={`${css(computedStyles)} ${selected && !asTrigger && 'selected'} ${
							asTrigger ? 'select-trigger' : 'select-option'
						}${option.preventClose ? ' select-option-hover-prevented' : ''}`}
						title={option.hoverText || option.text}
					>
						<div className={`${css(styleSheet.optionInner)} select-option-inner`}>
							<div className={`${css(styleSheet.selectText)} select-option-text`}>{option.text}</div>
						</div>
					</div>
				);
		}
	};
}

export class Select<T = any> extends SelectBase<T, ISelectProps<T>, ISelectState<T>> {
	public static getDerivedStateFromProps<T = any>(props: ISelectProps, state: ISelectState<T>) {
		const nextState: ISelectState<T> = {};

		if (!equal(props.options, state.options)) {
			nextState.options = props.options;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: ISelectProps<T>) {
		super(props);

		this.state = {
			isHovering: false,
			isOpen: false,
			options: props.options,
		};
	}

	protected onClose = () => {
		if (this.mMounted) {
			this.setState({ isOpen: false });
		}
	};

	private onOptionClick = (option: ISelectOption<T>) => () => {
		const { onOptionClick } = this.props;

		this.clearTimeout();
		onOptionClick?.(option, true);
		this.onClose();
	};

	protected onTriggerClick = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();
		const { onTriggerClick } = this.props;
		const { isOpen } = this.state;
		const isNowOpen = !isOpen;

		this.setState({ isOpen: isNowOpen }, isNowOpen ? this.scrollToSelectedOption : null);
		onTriggerClick?.(isNowOpen);
	};

	protected renderOptions = () => {
		const { openOnHover, optionStyles = [], options, selectedOption } = this.props;

		return options.map(option => {
			if (!option) {
				return null;
			}
			return (
				<div
					className={`${css(styleSheet.optionContainer, ...optionStyles)} option-container`}
					id={option.id}
					key={option.id}
					onClick={this.onOptionClick(option)}
					onMouseEnter={openOnHover && this.onHover(true)}
					onMouseLeave={openOnHover && this.onHover(false)}
				>
					{option.component?.(styleSheet.option, option.id === selectedOption?.id) ??
						this.renderOption(option, option.id === selectedOption?.id)}
				</div>
			);
		});
	};

	protected renderTrigger = () => {
		const {
			disabled,
			selectedOption,
			selectedOptionTitle,
			onRenderPlaceholder,
			triggerCaretFillColor = darkGrayFontColor,
			triggerStyles = [],
			showCaret = true,
			openOnHover,
		} = this.props;
		const trigger = selectedOptionTitle
			? typeof selectedOptionTitle === 'function'
				? selectedOptionTitle(selectedOption)
				: selectedOptionTitle
			: !selectedOption && !!onRenderPlaceholder
				? onRenderPlaceholder()
				: this.renderOption(selectedOption, true, true);
		return (
			<Measure client={true} onResize={this.onAnchorResized}>
				{({ measureRef }) => {
					return (
						<div
							className={css(styleSheet.triggerContainer, ...triggerStyles)}
							onClick={!disabled ? this.onTriggerClick : undefined}
							onMouseEnter={
								!disabled && openOnHover ? this.onTargetHover(true, this.scrollToSelectedOption) : undefined
							}
							onMouseLeave={!disabled && openOnHover ? this.onTargetHover(false) : undefined}
							ref={measureRef}
						>
							{openOnHover ? (
								<button
									className='more-menu-trigger'
									disabled={disabled}
									onMouseEnter={this.onTargetHover(true, this.scrollToSelectedOption)}
									onMouseLeave={this.onTargetHover(false)}
								>
									{trigger}
								</button>
							) : (
								trigger
							)}
							{showCaret && (
								<DisclosureIcon className={css(styleSheet.triggerCaret)} fillColor={triggerCaretFillColor} />
							)}
						</div>
					);
				}}
			</Measure>
		);
	};

	private scrollToSelectedOption = () => {
		const { maxOptionsToShow = DEFAULT_MAX_OPTIONS_TO_SHOW, selectedOption } = this.props;
		const { options, isOpen } = this.state;

		if (isOpen && options.length > maxOptionsToShow && !!selectedOption?.id) {
			setTimeout(() => {
				const el = document.getElementById(selectedOption.id);
				if (el) {
					el.scrollIntoView();
				}
			}, 10);
		}
	};
}

export class MultiSelect<T = any> extends SelectBase<T, IMultiSelectProps<T>, IMultiSelectState<T>> {
	public static getDerivedStateFromProps<T = any>(props: IMultiSelectProps, state: IMultiSelectState<T>) {
		const nextState: IMultiSelectState<T> = {};

		if ((!!props.selectAllOption || !state.isOpen) && !equal(props.selectedOptions, state.selectedOptions)) {
			nextState.selectedOptions = props.selectedOptions;
		}

		if (!equal(props.options, state.options)) {
			nextState.options = props.options;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IMultiSelectProps<T>) {
		super(props);
		let forceSelectAllFound = false;

		props.options.forEach(option => {
			forceSelectAllFound = option.forceSelectAll || forceSelectAllFound;
		});

		this.state = {
			forceSelectAllFound,
			isHovering: false,
			isOpen: false,
			options: props.options,
			selectedOptions: props.selectedOptions,
		};
	}

	protected onClose = () => {
		const { onClose } = this.props;

		onClose?.(this.state.selectedOptions);

		if (this.mMounted) {
			this.setState({ isOpen: false });
		}
	};

	private onOptionClick = (option: ISelectOption<T>) => () => {
		const { onOptionClick, options, selectAllIfNoneSelected } = this.props;
		const { selectedOptions } = this.state;
		let newSelectedOptions = [...selectedOptions];
		const wasSelected = !selectedOptions?.find(o => o.id === option.id);

		if (wasSelected) {
			newSelectedOptions = option.forceSelectAll
				? (newSelectedOptions = [...options])
				: options.filter(o => o.id === option.id || selectedOptions?.find(so => so.id === o.id));
		} else {
			// deselects all selected options if show all is deselected and
			// selectAllIfNoneSelected = false
			if (!selectAllIfNoneSelected && option?.forceSelectAll) {
				newSelectedOptions = [];
			} else {
				newSelectedOptions = newSelectedOptions.filter(o => o.id !== option.id).filter(o => !o.forceSelectAll);

				if (selectAllIfNoneSelected && !newSelectedOptions.length) {
					newSelectedOptions = [...options];
				}
			}
		}

		const selection = onOptionClick?.(option, wasSelected);

		this.setState({ selectedOptions: selection ? selection : newSelectedOptions });
	};

	protected onTriggerClick = () => {
		const { onClose, onTriggerClick } = this.props;
		const { isOpen, selectedOptions } = this.state;
		const isNowOpen = !isOpen;

		if (!isNowOpen) {
			onClose?.(selectedOptions);
		}

		this.setState({ isOpen: isNowOpen });
		onTriggerClick?.(isNowOpen);
	};

	protected onAllClick = () => {
		const { onAllClicked } = this.props;
		onAllClicked?.();
	};

	protected renderOptions = () => {
		const { isAllSelected, selectAllOption, openOnHover, optionStyles = [], options } = this.props;
		const { selectedOptions } = this.state;

		const elements = options.map((option, i) => {
			return (
				<div
					className={css(styleSheet.optionContainer, ...optionStyles)}
					id={option.id}
					key={option.id || i}
					onClick={this.onOptionClick(option)}
					onMouseEnter={openOnHover && this.onHover(true)}
					onMouseLeave={openOnHover && this.onHover(false)}
				>
					{option.component?.(styleSheet.option, !!selectedOptions?.find(o => o.id === option.id), false) ??
						this.renderOption(option, !!selectedOptions?.find(o => o.id === option.id), false)}
				</div>
			);
		});
		if (selectAllOption) {
			elements.unshift(
				<div
					className={css(styleSheet.optionContainer, ...optionStyles)}
					id='all'
					key='all'
					onClick={this.onAllClick}
					onMouseEnter={openOnHover && this.onHover(true)}
					onMouseLeave={openOnHover && this.onHover(false)}
				>
					{this.renderOption(selectAllOption, isAllSelected)}
				</div>
			);
		}

		return elements;
	};

	protected renderTrigger = () => {
		const {
			disabled,
			selectedOptionsTitle,
			onRenderPlaceholder,
			triggerCaretFillColor = darkGrayFontColor,
			triggerStyles = [],
			showCaret = true,
			openOnHover,
		} = this.props;
		const { selectedOptions = [] } = this.state;

		const trigger = selectedOptionsTitle
			? typeof selectedOptionsTitle === 'function'
				? selectedOptionsTitle(selectedOptions)
				: selectedOptionsTitle
			: selectedOptions.length === 0 && !!onRenderPlaceholder
				? onRenderPlaceholder()
				: this.renderOption(selectedOptions[0], true);
		return (
			<Measure client={true} onResize={this.onAnchorResized}>
				{({ measureRef }) => {
					return (
						<div
							className={css(styleSheet.triggerContainer, ...triggerStyles)}
							onClick={!disabled ? this.onTriggerClick : undefined}
							onMouseEnter={!disabled && openOnHover ? this.onTargetHover(true) : undefined}
							onMouseLeave={!disabled && openOnHover ? this.onTargetHover(false) : undefined}
							ref={measureRef}
						>
							{openOnHover ? (
								<button
									className='more-menu-trigger'
									disabled={disabled}
									onMouseEnter={this.onTargetHover(true)}
									onMouseLeave={this.onTargetHover(false)}
								>
									{trigger}
								</button>
							) : (
								trigger
							)}
							{showCaret && (
								<DisclosureIcon className={css(styleSheet.triggerCaret)} fillColor={triggerCaretFillColor} />
							)}
						</div>
					);
				}}
			</Measure>
		);
	};
}

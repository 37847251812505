import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const PlusIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`plus-icon ${className || ''}`} height={11} width={11}>
			<path
				fill={fillColor || brandSecondary}
				fillRule='evenodd'
				d='M6.5,4.5 L11,4.5 L11,6.5 L6.5,6.5 L6.5,11 L4.5,11 L4.5,6.5 L0,6.5 L0,4.5 L4.5,4.5 L4.5,0 L6.5,0 L6.5,4.5 Z'
			/>
		</SvgIcon>
	);
};

export const SUPPRESS_DUPLICATE_CAMPAIGN_MODAL_KEY = 'SUPPRESS_DUPLICATE_CAMPAIGN_MODAL_KEY';
export const SUPPRESS_DUPLICATE_POST_MODAL_KEY = 'SUPPRESS_DUPLICATE_POST_MODAL_KEY';

export enum EDuplicateType {
	User = 'User',
	Account = 'Account',
}

/**
 * Method to get the local storage for modal suppression
 *
 * @returns {Array} Array Of {Object} with {id} of the campaign template
 */
export const getLocalStorageSuppression = (key: string): { id: string }[] => {
	const ls = window.localStorage.getItem(key);
	return ls ? JSON.parse(ls) : [];
};

/**
 * Method to set the local storage for modal suppression
 *
 * @param key { string } to set the local storage
 * @param TemplateId { string } id to be stored in local storage
 */
export const setLocalStorageSuppression = (key: string, TemplateId: string) => {
	const storage = getLocalStorageSuppression(key);
	if (storage.length > 0) {
		window.localStorage.setItem(key, JSON.stringify([...storage, { id: TemplateId }]));
		return;
	}
	window.localStorage.setItem(key, JSON.stringify([{ id: TemplateId }]));
};

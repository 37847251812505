import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { EnvironmentKey, IEnvironmentComponentProps } from '../../../models/AppState';
import { DayPicker } from '../../../web/components/DayPicker';
import { PopoverType, TinyPopover } from '../../../web/components/TinyPopover';
import { DeprecatedXIcon } from '../../../web/components/svgs/icons/DeprecatedXIcon';
import { styleSheet } from './styles';

interface IProps extends IEnvironmentComponentProps {
	anchor?: React.ReactNode;
	isOpen?: boolean;
	onDaySelected?(day: Date): void;
	onRequestClose?(): void;
	selectedDay?: Date;
}

class _NextMeetingPopover extends React.Component<IProps> {
	public render() {
		const { anchor, environment, isOpen, onDaySelected, onRequestClose, selectedDay } = this.props;
		return (
			<TinyPopover
				align='center'
				anchor={anchor}
				arrow={{
					arrowColor: '#f2f2f2',
					arrowSize: 10,
					arrowStyle: { display: 'block' },
				}}
				dismissOnOutsideAction={true}
				isOpen={isOpen}
				onRequestClose={onRequestClose}
				placement={['top']}
				type={PopoverType.white}
			>
				<div>
					<span className={css(styleSheet.nextMeetingHeader)}>
						When is your next meeting with the client?
						<button onClick={onRequestClose}>
							<DeprecatedXIcon />
						</button>
					</span>
					<DayPicker
						allowPastDates={false}
						environment={environment}
						onDayClick={onDaySelected}
						selectedDays={selectedDay || new Date()}
					/>
				</div>
			</TinyPopover>
		);
	}
}

export const NextMeetingPopover = inject(EnvironmentKey)(_NextMeetingPopover);

import { ContactViewModel, EntityViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { baseStyleSheet } from '../../../styles/styles';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { BulletIcon } from '../../svgs/icons/BulletIcon';
import { DeprecatedXIcon } from '../../svgs/icons/DeprecatedXIcon';
import { EditItemPenIcon } from '../../svgs/icons/EditItemPenIcon';
import { RoundAddIcon } from '../../svgs/icons/RoundAddIcon';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { InlineValueEditor } from '../InlineValueEditor';
import { styleSheet } from './styles';

export interface IAddPhoneNotification {
	content: JSX.Element;
	type?: 'important' | 'standard';
}

interface IProps<T = any> extends IEventLoggingComponentProps {
	addButtonContent: React.ReactNode;
	addPhoneNotification?: IAddPhoneNotification;
	className?: string;
	compactLayout?: boolean;
	deleteConfirmationContent?: string;
	disabled?: boolean;
	disableHoverOnItem?(item: T): boolean;
	editItemIndex?: number;
	editTextValue?: string;
	entity?: EntityViewModel;
	itemClassName?: string;
	itemEditingClassName?: string;
	items?: T[];
	newItemFooterClassName?: string;
	newItemFooterEditingClassName?: string;
	onAddNewButtonClicked?(): void;
	onEditCanceled?(): void;
	onEditTextChanged?(value: string): void;
	onItemEditButtonClicked?(item: T, index: number): void;
	onRemoveItem?(item: T): void;
	onRenderItem(item: T, index: number, bullet?: React.ReactNode, editButton?: React.ReactNode): React.ReactNode;
	onRenderItemEditor?(item?: T, removeButton?: React.ReactNode): React.ReactNode;
	onSaveEdit?(): void;
	onUpdateItem?(item: T): void;
	showDeleteConfirmation?: boolean;
	showEditNewItemInput?: boolean;
	children?: React.ReactNode;
}

interface IState<T = any> {
	disabled?: boolean;
	editItemIndex?: number;
	editTextValue?: string;
	items?: T[];
	itemToBeDeletedIndex?: number;
	showEditNewItemInput?: boolean;
}

class _EntityInfoBulletList<T = any> extends React.Component<IProps<T>, IState<T>> {
	constructor(props: IProps<T>) {
		super(props);
		const { disabled, items } = props;
		this.state = {
			disabled,
			items: items || [],
		};
	}

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};
		if (props.items !== state.items) {
			nextState.items = props.items;
		}
		if (props.showEditNewItemInput !== state.showEditNewItemInput) {
			nextState.showEditNewItemInput = props.showEditNewItemInput;
		}
		if (props.editItemIndex !== state.editItemIndex) {
			nextState.editItemIndex = props.editItemIndex;
		}
		if (props.editTextValue !== state.editTextValue) {
			nextState.editTextValue = props.editTextValue;
		}
		if (props.disabled !== state.disabled) {
			nextState.disabled = props.disabled;
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	public render() {
		const {
			entity,
			addButtonContent,
			addPhoneNotification,
			disableHoverOnItem,
			newItemFooterClassName,
			itemClassName,
			itemEditingClassName,
			newItemFooterEditingClassName,
			children,
		} = this.props;
		const { items, disabled, showEditNewItemInput, editItemIndex, itemToBeDeletedIndex } = this.state;
		const editingExistingItem = editItemIndex !== null && editItemIndex !== undefined && editItemIndex >= 0;
		const dataOriginEnabled = entity instanceof ContactViewModel && (entity as ContactViewModel).dataOriginEnabled;
		return (
			<div className={`${css(styleSheet.container)} entity-info-bullet-list ${this.props.className || ''}`}>
				<ul className={css(styleSheet.list)}>
					{items.map((item, i) => {
						const isEditing = i === editItemIndex;
						const className = `${css(
							itemClassName ? null : styleSheet.item,
							!editingExistingItem && !dataOriginEnabled && !disableHoverOnItem?.(item)
								? styleSheet.itemWithHover
								: null,
							itemToBeDeletedIndex === i ? styleSheet.itemToBeDeleted : null,
							isEditing ? (!itemEditingClassName ? styleSheet.itemEditing : null) : null
						)} ${itemClassName || ''} ${itemEditingClassName || ''}`;
						return (
							<li className={className} key={`${item}-${i}`}>
								{isEditing ? this.onRenderEditor(true, item) : this.renderItem(item, i)}
							</li>
						);
					})}
					{children}
				</ul>
				<div
					className={`${css(styleSheet.footer)} ${newItemFooterClassName || ''} ${
						showEditNewItemInput ? newItemFooterEditingClassName || '' : ''
					}`}
				>
					<TinyPopover
						isOpen={!!addPhoneNotification?.content && !showEditNewItemInput}
						dismissOnOutsideAction={false}
						align='start'
						placement={['top', 'bottom']}
					>
						<div
							className={css(
								styleSheet.addPhoneMessage,
								addPhoneNotification?.type === 'important' && styleSheet.addPhoneMessageImportant
							)}
						>
							<button
								className='brand-link'
								// @ts-ignore
								disabled={!!disabled || editItemIndex >= 0}
								onClick={this.onAddNewButtonClicked}
							>
								{addPhoneNotification?.content}
							</button>
						</div>
					</TinyPopover>
					<RoundAddIcon className={`${css(styleSheet.plusIcon)} entity-info-bullet-list-footer-icon`} />
					{showEditNewItemInput ? (
						this.onRenderEditor(false)
					) : (
						<button
							className='brand-link'
							// @ts-ignore
							disabled={!!disabled || editItemIndex >= 0}
							onClick={this.onAddNewButtonClicked}
						>
							<span>{addButtonContent}</span>
						</button>
					)}
				</div>
			</div>
		);
	}

	private onDeleteConfirmationRequestClose = () => {
		// @ts-ignore
		this.setState({ itemToBeDeletedIndex: null });
	};

	private renderItem(item: T, index: number) {
		const { deleteConfirmationContent, onRenderItem } = this.props;
		const { disabled, itemToBeDeletedIndex } = this.state;
		const bullet = (
			<div className={`${css(styleSheet.bullet)} entity-info-bullet-list-item-bullet`}>
				<BulletIcon />
			</div>
		);
		const editButton = (
			<>
				<button
					className={`entity-info-bullet-list-item-edit-button ${css(styleSheet.editButton)}`}
					disabled={!!disabled}
					onClick={this.onItemEditButtonClicked(item, index)}
				>
					<EditItemPenIcon />
				</button>
				<TinyPopover
					align='end'
					anchor={
						<button
							className={`entity-info-bullet-list-item-edit-button ${css(styleSheet.deleteButton)}`}
							disabled={!!disabled}
							onClick={this.onItemTrashButtonClicked(index)}
						>
							<TrashIcon />
						</button>
					}
					arrow={{
						arrowSize: 10,
					}}
					arrowStyles={[styleSheet.deleteItemConfirmationArrow]}
					dismissOnOutsideAction={true}
					isOpen={itemToBeDeletedIndex === index}
					onRequestClose={this.onDeleteConfirmationRequestClose}
					placement={['top']}
					styles={[styleSheet.deleteItemConfirmationPopover]}
					type={PopoverType.blue}
				>
					<div className={css(styleSheet.deleteItemConfirmationContainer)}>
						<div
							className={css(styleSheet.closeDeleteConfirmationContainer)}
							onClick={this.onDeleteConfirmationRequestClose}
						>
							<DeprecatedXIcon />
						</div>
						<p>{deleteConfirmationContent}</p>
						<button className={css(baseStyleSheet.ctaButtonDestructiveSolid)} onClick={this.onRemoveItemButtonClicked}>
							Delete
						</button>
					</div>
				</TinyPopover>
			</>
		);

		return onRenderItem(item, index, bullet, editButton);
	}

	private onRenderEditor = (canRemove: boolean, item?: T) => {
		const { disabled, editTextValue } = this.state;
		const { onRenderItemEditor, compactLayout } = this.props;

		const removeButton = canRemove ? (
			<div className={`${css(styleSheet.bullet)} entity-info-bullet-list-item-bullet`}>
				<button onClick={this.onRemoveItemButtonClicked}>
					<DeprecatedXIcon className={css(styleSheet.itemRemoveIcon)} />
				</button>
			</div>
		) : null;

		if (onRenderItemEditor) {
			return onRenderItemEditor(item, removeButton);
		}

		return (
			<>
				{!!canRemove && removeButton}
				<InlineValueEditor
					autoComplete='off'
					autoFocus={true}
					className={css(styleSheet.editItem)}
					compactLayout={compactLayout}
					disabled={!!disabled}
					id='entity-info-bullet-list-editor-input'
					onCancelButtonClicked={this.onEditorCancelButtonClicked}
					onChange={this.onEditorValueChanged}
					onKeyDown={this.onEditorInputKeyDown}
					onSaveButtonClicked={this.onEditorSaveButtonClicked}
					value={editTextValue || ''}
				/>
			</>
		);
	};

	private onEditorCancelButtonClicked = () => {
		const { onEditCanceled } = this.props;
		if (onEditCanceled) {
			onEditCanceled();
		} else {
			this.setState({
				editItemIndex: -1,
				showEditNewItemInput: false,
			});
		}
	};

	private onEditorSaveButtonClicked = () => {
		const { onSaveEdit } = this.props;
		if (onSaveEdit) {
			onSaveEdit();
		} else {
			this.setState({
				editItemIndex: -1,
				// @ts-ignore
				editTextValue: null,
				showEditNewItemInput: false,
			});
		}
	};

	private onEditorValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { onEditTextChanged } = this.props;
		const value = e.target.value;
		if (onEditTextChanged) {
			onEditTextChanged(value);
		} else {
			this.setState({
				editTextValue: value,
			});
		}
	};

	private onEditorInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { onSaveEdit } = this.props;
		if (e.keyCode === 13 && !!onSaveEdit) {
			// enter
			onSaveEdit();
		}
	};

	private onItemTrashButtonClicked = (index: number) => () => this.setState({ itemToBeDeletedIndex: index });

	private onItemEditButtonClicked = (item: T, index: number) => () => {
		const { onItemEditButtonClicked } = this.props;
		if (onItemEditButtonClicked) {
			onItemEditButtonClicked(item, index);
		}
	};

	private onAddNewButtonClicked = () => {
		const { onAddNewButtonClicked } = this.props;
		if (onAddNewButtonClicked) {
			onAddNewButtonClicked();
		} else {
			this.setState({
				editItemIndex: -1,
				showEditNewItemInput: true,
			});
		}
	};

	private onRemoveItemButtonClicked = () => {
		const { onRemoveItem } = this.props;
		const { itemToBeDeletedIndex, editItemIndex, items } = this.state;
		const itbdIndex = itemToBeDeletedIndex;

		// @ts-ignore
		this.setState({ itemToBeDeletedIndex: null });

		// @ts-ignore
		const nextState: IState = { itemToBeDeletedIndex: null };
		const index =
			typeof editItemIndex === 'number' && editItemIndex >= 0
				? editItemIndex
				: typeof itbdIndex === 'number' && itbdIndex >= 0
					? itbdIndex
					: null;

		if (index !== null) {
			if (onRemoveItem) {
				// @ts-ignore
				onRemoveItem(items[index]);
			} else {
				const nextItems = [...(items || [])];
				nextItems.splice(index, 1);
				nextState.items = nextItems;
			}
		}

		this.setState(nextState);
	};
}

// @ts-ignore
const EntityInfoBulletListAsObserver = observer(_EntityInfoBulletList);
export const EntityInfoBulletList = withEventLogging(EntityInfoBulletListAsObserver, 'EntityInfoBulletList');
